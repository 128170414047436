import React from "react";
import rightChatstyle from "./Chat.module.css";
import { formatTimestamp, messageTime, splitSenderName } from "../components/utils/utlis";
 // Make sure to import your CSS module
 import styles from "./widgets/dynamicDocument.module.css"; 
 import { FaCheck, FaCheckDouble, FaExclamationCircle, FaClock } from "react-icons/fa";
import { renderStatusIcon } from "./widgets/renderMessageStatus";
 export default function RightChatTextWidget({ Name, time, message, botImage, status }) {
  const date = new Date(time);
  const timeDate = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const base64ToUrl = (base64String) => `data:image/jpeg;base64,${base64String}`;

  // Function to render status icon
 
  

  return (
    <div className={rightChatstyle["chat-message-right"] + " pb-4 d-flex"}>
      <center>
        <div className={styles[`avatar-right p2`]}>
          <img
            alt="Admin"
            src={botImage ? botImage : "https://bootdey.com/img/Content/avatar/avatar1.png"}
            className="rounded-circle"
            width={40}
            height={40}
            title={Name}
          />
          <div className="text-center text-wrap sender-name">
            {Name}
            <br />
            {formatTimestamp(time)}
          </div>
        </div>
      </center>

      <div
        className="flex-shrink-1 bg-light rounded pt-3 px-2 position-relative"
        style={{
          wordWrap: "break-word",
          maxWidth: "70%",
          minWidth:"50px",
          paddingTop:"8px"
        
        }}
      >
        {/* Status icon positioned at the top-right */}
        <div
          className="position-absolute"
          style={{
            top: "5px", // adjust this based on how far you want the icon from the top
            right: "5px",
            // adjust this based on how far you want the icon from the right
          }}
        >
          {renderStatusIcon(status)}
        </div>

        {/* The actual message */}
        {message}
      </div>
    </div>
  );
}
 

